.@{class-prefix}-retailer-add{
    .ant-steps-label-vertical{
        display: none !important;
    }

  }
.@{class-prefix}-blueBackground{
    background-color: @primary-5 !important;
    ::placeholder{
        // color: black !important;
        background-color: @primary-5 !important;
    }
  }
.@{class-prefix}-steps-title{
    color: @primary-1;
  }


