.@{class-prefix}-booking-tab{
    p{
        padding: 0;
        margin: 0;
    }
    .@{class-prefix}-mt{
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .@{class-prefix}-fs-15{
        font-size: 15px;
    }
    .@{class-prefix}-fs-40{
        font-size: 40px;
    }
    .@{class-prefix}-display-row{
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .@{class-prefix}-display-space-between{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
    .@{class-prefix}-display-space-arround{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-around;
    }
    .@{class-prefix}-fw-800{
        font-weight: 800;
    }
    .@{class-prefix}-fw-900{
        font-weight: 900;
    }
    .@{class-prefix}-head-20{
        font-size: 20px;
        margin-bottom: 10px;
    }

    .@{class-prefix}-sub-head{
        font-size: 20px;
        font-weight: 600;
    }

    .@{class-prefix}-ml-20{
       margin-left: 20px;
    }

    .@{class-prefix}-pl-20{
       padding-left: 40px;
     }

     .@{class-prefix}-wt-80{
       width: 80%;
      }

      .@{class-prefix}-fs-20{
        font-size: 20px;
    }

    .@{class-prefix}-card-el{
        height: 150px;
    }

    .@{class-prefix}-Total{
       font-size: 18px;
       font-weight: 600;
    }

    .@{class-prefix}-Icons{
        cursor: pointer;
        margin-left: 10px;
        color: red;
     }

     .@{class-prefix}-type{
        font-size: 20px;
        font-weight: 600;
        color: #10498A;
        margin-top: 15px;
    }

    .@{class-prefix}-Icon-tex{
       font-size: 16px;
       margin-top: 10px;
       font-weight: 600;
    }

    .@{class-prefix}-scan-tex{
        color: #10498A;
        font-weight: 500;
        font-size: 20px;
     }

     .@{class-prefix}-permission-text{
        font-weight: 500;
        font-size: 18px;
        margin-right: 10px;
     }


     .@{class-prefix}-Add{
        color: #10498A;
        font-weight: 900;
        margin-left: 5px;
        font-size: 15px;
        cursor: pointer;
     }


     .@{class-prefix}-Add-Icon{
        color: #10498A;
        font-size: 20px;
        cursor: pointer;
     }

     .@{class-prefix}-orniser-Event{
        color:#10498A;
        font-size: 13px;
        font-weight: 800;
     }
     .@{class-prefix}-orniser-span-text{
        font-size: 13px;
        font-weight: 600;
        color: black;
     }

     .@{class-prefix}-home-head{
        font-size: 20px;
        font-weight: 900;
     }
     .@{class-prefix}-home-sub-head{
        font-size: 15px;
        font-weight: 600;
     }

     .@{class-prefix}-home-text-center{
      text-align: center;
     }
     .@{class-prefix}-home-mt-25{
        margin-top: 25px;
       }

       .@{class-prefix}-home-mt-15{
        margin-top: 15px;
       }

       .@{class-prefix}-home-mb-15{
        margin-bottom: 15px;
       }





}