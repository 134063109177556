@import "error/index.less";
@import "authentication/index.less";
@import "profile/index.less";
@import "purchase/index.less";
@import "vehicleCounterSales/index.less";
@import "stockInOut/index.less";
@import "transaction/index.less";
@import "settings/index.less";
@import "stock/index.less";
@import "managerUsers/index.less";
@import "accounting/index.less";
@import "vendorDashboard/index.less";
@import "booking/index.less";
@import "./Sidemenu/index.less";
@import "./eventstabs/index.less";
@import "./Masters/index.less";

.@{class-prefix}-mobile-none {
    @media @sm {
        display: none;
    }
}

.@{class-prefix}-isMobile {
    display: none;

    @media @sm {
        display: block;
    }
}

.ant-table.ant-table-middle .ant-table-tbody > tr > td{
    padding: 7px 10px;
}

.ant-table.ant-table-middle .ant-table-thead > tr > th{
    padding: 10px 10px;
}

.ant-select-selection-selected-value {
    width: 100%;
  }
  
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
    overflow: hidden;
}

.@{class-prefix}-text-color-white{
    color: #ffffff;
}
input:read-only:not([type="checkbox"]):not([type="radio"]) {
    cursor:not-allowed;
}
