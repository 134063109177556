.@{class-prefix}-receipt-list {
    .ant-card-body{
        padding: 15px;
    }
    .@{class-prefix}-font-size-13{
        font-size: 13px;
        color: #5b5a5a;
    }
}


.@{class-prefix}-receipt-view{
    .@{class-prefix}-font-size-13{
        font-size: 13px;
        color: #5b5a5a;
    }
    // .ant-table-tbody > tr > td {
    //     border-bottom: 1px solid #fff; 
    // }
}

.@{class-prefix}-invoice-card{
    .ant-table-thead > tr > th{
        color: #2d3436;
        font-weight: 500;
        font-size: 12;
        line-height: 18px;
        text-align: left;
        background: #ffffff !important;
        border-bottom: 1px solid #ffffff !important;
        padding: 0;
    }
    .ant-table-tbody > tr > td{
        border-bottom: 1px solid #ffffff !important;
        padding: 16px 0 0;
    }
    p{
        margin: 0;
        color: #636e72;
        line-height: 21px;
    }
}