.ant-select-focused:not(.ant-select-disabled).ant-select:not(
		.ant-select-customize-input
	)
	.ant-select-selector {
	box-shadow: @input-focus;
}

.ant-select-selection-placeholder {
	flex: none !important;
}

.ant-select-selection-item {
	flex: none !important;
}

.ant-select-item-option-content {
	flex: none !important;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
