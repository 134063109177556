.@{class-prefix}-billing-details-container{
  // height: 80vh;
  // overflow-y: scroll;

  &::-webkit-scrollbar{
    display: none;
  }
}
.@{class-prefix}-billing-schemes-collapse{
  .custom-collapse-item > .custom-collapse-header {
    padding: 0px !important;
  }
}
.@{class-prefix}-retailer-add-container{
  .ant-picker-borderless{
    background:@primary-5 !important;
  }
  .card1{
    width: 100%;
    height: 150px;
    box-shadow: 0px 0px 10px lightgray;
    position: relative;
    overflow-x: auto;
    overflow-y: none;

    &::-webkit-scrollbar{
      background-color: @primary-4;
      // border-radius: 10px;
      height: 12px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-thumb{
      background-color: @primary-1;
      border-radius: 10px;
      cursor: grab;
    }
    &::-webkit-scrollbar-thumb{
      background-color: @primary-1;
      border-radius: 10px;
      cursor: grab;
    }
  }
}