.@{class-prefix}-vendor-dashboard-list{
    .ant-card-body{
        padding: 15px;
    }
    .ant-card-actions > li {
        margin: 8px 0px;
    }
    .@{class-prefix}-scroll{
        height: 550px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    ::-webkit-scrollbar {
        width: 7px;
    }
    ::-webkit-scrollbar-track {
        background-color: #ebebeb;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 7x;
        border-radius: 7px;
        background: #c1c1c1;
    }
    
}

// .@{class-prefix}-vendor-dashboard{
//     .@{class-prefix}-card-hover:hover::after{
//         clip-path: circle(100%);
//     }
//     .@{class-prefix}-card-hover::after{
//         // background: rgba(71,124,219,.3);
//         // -webkit-box-shadow: 0 12px 30px 0 rgb(10 9 48 / 15%);
//         // box-shadow: 0 12px 30px 0 rgb(10 9 48 / 15%);
//         // transform: translateY(-4px);
//         content: '';
//         background: rgba(71,124,219,.3);
//         border-radius: 10px;
//         height: 100%;
//         width: 100%;
//         position: absolute;
//         left: 0;
//         top: 0;
//         z-index: 0;
//         clip-path: circle(10% at 0% 0%);         
//         transition: all .3s ease-in;
//     }
// }



.@{class-prefix}-card-contact{
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #6f6f7b;
    font-family: "DM Sans", sans-serif;
    line-height: 1.6;
    cursor: pointer;
    margin-bottom: 10px;
}
.@{class-prefix}-card-contact svg{
    flex-shrink: 0;
    width: 30px;
    min-height: 34px;
    margin-right: 10px;
    transition: 0.3s;
    padding-right: 10px;
    border-right: 1px solid #dfe2ec;
}