.@{class-prefix}-purchase-details {
    .hp-purchase-app-content .ant-list-items {
        display: block;
        flex-wrap: wrap;
        margin: 0px;
    }

    .ant-list-item-meta-title {
        color: rgba(0, 0, 0, 0.45);
        font-size: 13px;
        line-height: 1.5715;
    }

    .ant-list-item-meta-description {
        margin-bottom: 4px;
        color: #2d3436;
        font-size: 14px;
        line-height: 1.5715;
        font-weight: 600;
    }

    .ant-list-item {
        padding: 10px;
        display: flex;
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header {
        padding: 10px !important;
    }

    .ant-image {
        display: block;
        border: 1px solid #ffffff;
        padding: 5px;
        margin: 0px;
    }

    .ant-collapse-content>.ant-collapse-content-box {
        padding: 16px 10px !important;
    }

    .ant-table-footer {
        background-color: #fff !important;
    }

    .@{class-prefix}-preview-img {
        width: 100%;
        height: 150px;
        
    }

    .@{class-prefix}-edit-icon {
        margin-left: -15px;
    }

    .@{class-prefix}-font-size-12 {
        font-size: 12px;
    }

}


.ant-image-preview-operations-operation {
    margin-left: 16px;
    padding: 16px;
    cursor: pointer;
    color: #fff;
    background: #0c0c0c61;
}


.@{class-prefix}-approved-btn {
    background-color: #d0f1de;
    color: #2d6b52;
    border: 1px solid #f9fffd;
}

.@{class-prefix}-approved-btn:hover {
    background-color: #d0f1de;
    color: #2d6b52;
    border: 2px solid #f9fffd;
}


.@{class-prefix}-demo-loadmore-list {
    @media @sm {
        .ant-list-item-meta {
            flex: 0 0 100%;
            margin-bottom: 24px
        }
    }
}

// .hp-purchase-app-content .ant-list-items {
//     display: inline-block;
//     flex-wrap: wrap;
//     margin: 0 -16px;
// }

@media @sm {
    .ant-list-lg .ant-list-item {
        padding: 16px 0 16px;
    }
}

@media @sm {
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
        flex: none;
    }
}