// .@{class-prefix}-transaction-content {
//     .ant-list-items {
//         display: contents;
//     }
//     .ant-card-body {
//         padding: 5px
//     }
//     .@{class-prefix}-scroll {
//         height: 550px;
//         overflow-y: scroll;
//         overflow-x: hidden;

//     }
//     ::-webkit-scrollbar {
//         width: 7px;
//     }
//     ::-webkit-scrollbar-track {
//         background-color: #ebebeb;
//         -webkit-border-radius: 10px;
//         border-radius: 10px;
//     }
//     ::-webkit-scrollbar-thumb {
//         -webkit-border-radius: 7x;
//         border-radius: 7px;
//         background: #c1c1c1;
//     }

//     .ant-tabs-tab + .ant-tabs-tab {
//         margin: 0 0 0 55px;
//     }

//     .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{
//         position: relative;
//         display: inline-block;
//         display: flex;
//         flex: auto;
//         align-self: stretch;
//         white-space: nowrap;
//         overflow: visible;
//         transform: translate(0);
//     }
// }


.@{class-prefix}-current-ride-details{
    .@{class-prefix}-ft-13{
        font-size: 13px;
        color: #5b5a5a;
    }
    .@{class-prefix}-card-contact{
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #6f6f7b;
        font-family: "DM Sans", sans-serif;
        line-height: 1.6;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .@{class-prefix}-card-contact svg{
        flex-shrink: 0;
        width: 30px;
        min-height: 34px;
        margin-right: 10px;
        transition: 0.3s;
        padding-right: 10px;
        border-right: 1px solid #dfe2ec;
    }
    .ant-card-body {
        padding: 20px;
    }
    .@{class-prefix}-card{
        padding: 0px;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #2d3436;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        background: #fff;
        border-radius: 7px;
        border: 1px solid #f0f0f0;
    }
    .@{class-prefix}-card-header{
        img{
            width: 100%;
            margin-top: -8px;
            border-radius: 7px 7px 15px 15px;
        }
    }
    .@{class-prefix}-ft-15{
        font-size: 15px;
    }

    
}