.@{class-prefix}-booking-grid {
    .@{class-prefix}-ft-13{
        font-size: 13px;
        color: #5b5a5a;
    }
    .ant-card-body{
        padding: 20px;
    }
    .@{class-prefix}-float-tight{
        float: right;
    }
}